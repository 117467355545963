<template>
  <div>
    <div class="grid" v-if="user">
        <div class="col-12 xl:col-6">
            <div class="card height-100 flex flex-column">
                <div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
                    <!-- <img src="layout/images/widgets/feature-faq.svg" alt="feature-faq"> -->
                    <h2 class="m-0">Benutzer</h2>
                </div>
                <div class="formgrid mt-3">
                    <div class="field col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Username</span>
                            <InputText v-model="user.username" placeholder="franzi" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Name</span>
                            <InputText v-model="user.fullname" placeholder="Franz Huber" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">E-Mail</span>
                            <InputText id="emailAddress" v-model.trim="user.notifications.emailAddress" required="false" autofocus />
                        </div>
                        <div class="p-inputgroup" v-if="isMaster">
                            <span class="p-inputgroup-addon">Erlaubte Anlagen</span>
                            <MultiSelect v-model="user.config.departments" display="chip" :options="getDepartments" optionLabel="key" placeholder="Anlagen" class="w-full md:w-20rem">
                            <template #option="slotProps">
                                <div class="flex align-items-center">
                                    <div>[{{ slotProps.option.key }}] {{ slotProps.option.label }}</div>
                                </div>
                              </template>
                            </MultiSelect>
                        </div>
                        <div class="p-inputgroup field-checkbox">
                            <span class="p-inputgroup-addon">GUI-Animationen</span>
                            <Checkbox id="guiAnimations" name="guiAnimations" v-model="user.config.animations" :binary="true" class="ml-3" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">User-ID</span>
                            <InputText id="_id" v-model="user._id" readonly />
                        </div>
                    </div>
                    <Divider />
                    <div class="field col">
                      <div class="p-inputgroup">
                          <span class="p-inputgroup-addon">Passwort</span>
                          <InputText id="password" type="password" v-model.trim="user.password" required="true" autofocus
                            :class="{ 'p-invalid': changePwd && user.password !== retypePwd }" @click="changePassword" />
                          <small class="p-error" v-if="submitted && !user.password">Passwort <b>muss</b> ausgefüllt
                            werden!</small>
                      </div>
                      <div class="p-inputgroup" v-if="changePwd">
                          <span class="p-inputgroup-addon">Passwort wiederholen</span>
                          <InputText id="retypePwd" type="password" v-model.trim="retypePwd" required="true" autofocus
                            :class="{ 'p-invalid': user.password !== retypePwd }" />
                          <small class="p-error" v-if="submitted && !user.retypePwd">Passwort <b>muss</b> ausgefüllt
                            werden!</small>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-6">
            <div class="card height-100 flex flex-column">
                <div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
                    <!-- <img src="layout/images/widgets/feature-faq.svg" alt="feature-faq"> -->
                    <h2 class="m-0">Meldungen</h2>
                </div>
                <div class="formgrid mt-3">
                    <div class="field col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Meldung von Anlagen</span>
                            <MultiSelect v-model="user.notifications.departments" display="chip" :options="getDepartments" optionLabel="key" placeholder="Anlagen" class="w-full md:w-20rem">
                            <template #option="slotProps">
                                <div class="flex align-items-center">
                                    <div>[{{ slotProps.option.key }}] {{ slotProps.option.label }}</div>
                                </div>
                              </template>
                            </MultiSelect>
                        </div>
                        <div class="p-inputgroup field-checkbox">
                            <span class="p-inputgroup-addon">E-Mail Meldungen</span>
                            <Checkbox id="emailEnable" name="emailEnable" v-model="user.notifications.emailEnable" :binary="true" class="ml-3" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">E-Mail ab Alarmpriorität senden</span>
                            <Dropdown v-model="user.notifications.emailPrior" :options="getAlarmPrior" optionLabel="label" optionValue="value" placeholder="Alarmpriorität ab" class=""/>
                        </div>
                        <div class="p-inputgroup field-checkbox">
                            <span class="p-inputgroup-addon">Pushover Meldungen</span>
                            <Checkbox id="pushoverEnable" name="pushoverEnable" v-model="user.notifications.pushoverEnable" :binary="true" class="ml-3" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Pushover Key</span>
                            <InputText id="pushoverKey" v-model.trim="user.notifications.pushoverUserKey" required="false" autofocus />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Pushover ab Alarmpriorität senden</span>
                            <Dropdown v-model="user.notifications.pushoverPrior" :options="getAlarmPrior" optionLabel="label" optionValue="value" placeholder="Alarmpriorität ab" class=""/>
                        </div>
                        <div class="p-inputgroup field-checkbox">
                            <span class="p-inputgroup-addon">Pushover Priorität >1 muss quittiert werden?</span>
                            <Checkbox id="pushoverMustConfirm" name="pushoverMustConfirm" v-model="user.notifications.pushoverMustConfirm" :binary="true" class="ml-3" />
                        </div>
                    </div>
                    <Divider />
                </div>
            </div>
        </div>
    </div>
    <div class="flex justify-content-start">
        <div class="flex align-items-center justify-content-center m-2">
            <Button label="Speichern" @click="saveUser" />
        </div>
    </div>
  </div>

  <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" header="Bestätigung" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="ioDialogData">Soll das Passwort wirklich geändert werden?</span>
    </div>
    <template #footer>
      <Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
      <Button label="Ja" icon="pi pi-check" class="p-button-text" @click="passwordChange" />
    </template>
  </Dialog>

</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { mongoResponseToast } from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";
import { mapGetters } from "vuex";

export default {
  name: "UserTable",
  data() {
    return {
      loading: false,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      ioDialogData: {},
      user: null,
      retypePwd: null,
      changePwd: false,
      oldPassword: null,
      newPassword: null,
      selectedProducts: null,
      selectedIOs: null,
      filters: {},
      submitted: false,
      intval_Value: null,
    };
  },
  productService: null,
  created() {
    // this.productService = new ProductService();
    this.initFilters();
    this.$store.dispatch('users/loadUserGroups');
    this.$store.dispatch('types/loadDepartmentTypes');
  },
  mounted() {
    // this.productService.getProducts().then((data) => (this.products = data));
    this.loading = true;
    this.getParameter();
  },
  beforeUnmount() {
    this.resetDialogData();
    this.user = {};
    this.changePwd = null;
    this.retypePwd = null;
    this.changePwd = null;
    this.oldPassword = null;
    this.newPassword = null;
    this.selectedProducts = null;
    this.submitted = false;
    this.filters = {};
  },
  computed: {
    ...mapGetters({
      usersGroups: 'users/getUserGroups',
      isLoggedIn: 'auth/isLoggedIn',
      loginStatus: 'auth/loginStatus',
      getUser: 'auth/getUser',
      viewMenu: 'viewMenu',
      getDepartments: 'types/getDepartments',
      getAlarmPrior: 'types/getAlarmPrior',
      isMaster: 'auth/isMaster',
    }),
    // ...mapGetters('users', ['getUserGroups']),
  },
  methods: {
    resetDialogData() {
      this.user = {
        username: null,
        password: null,
        fullname: null,
        active: true,
        role: null,
        config: {
          theme: "dark",
          lang: "de-AT",
          departments: []
        },
        notifications: {
          pushoverEnable: false,
          pushoverUserKey: null,
          emailEnable: false,
          emailAddress: null
        },
        group: {}
      };
      this.oldPassword = null;
      this.newPassword = null;
      this.changePwd = false;
      this.retypePwd = null;
    },
    getTheUser() {
      this.loading = true;
      SocketioService.getUser(this.getUser.username, (err, response) => {
        if (err) {
          this.$toast.add({
            severity: "error",
            summary: `Fehler bei Server Anfrage`,
            detail: `Benutzer konnte nicht geladen werden`,
            life: 3000,
          });
        } else {
          console.log(response);
          console.log(this.getDepartments);
          this.user = response;
          this.loading = false;
        }
      });
    },
    getParameter() {
      this.getTheUser();
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.resetDialogData();
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
    },
    dialogClosed() {
      this.submitted = false;
      //this.resetDialogData();
      // clearInterval(this.intval_Value);
      this.getUserList();
    },
    changePassword() {
      if (!this.changePwd) this.deleteProductDialog = true;
    },
    passwordChange() {
      this.changePwd = true;
      this.oldPassword = this.user.password;
      this.user.password = null;
      this.deleteProductDialog = false;
    },
    saveUser() {
      this.submitted = true;
      let tempData = null;

      if (this.user.username.trim()) {
        tempData = this.user;

        if (this.changePwd) {
          if (this.user.password === this.retypePwd) {
            tempData.newPassword = this.user.password;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Passwörter kontrollieren",
              detail: "Die Passwörter stimmen nicht überein!",
              life: 3000,
            });
            return
          }
        }
        SocketioService.setUserList(tempData, (err, response) => {
            if (err) {
              this.$toast.add({
                severity: "error",
                summary: `Fehler bei Server Anfrage`,
                detail: `Benutzerliste konnte nicht geladen werden`,
                life: 3000,
              });
            } else {
              mongoResponseToast(response, this.$root);
              // this.system_user = response;
              this.loading = false; 
              this.changePwd = false;
              this.submitted = false;
            }
          });
        this.productDialog = false;
        //this.resetDialogData();
      } else {
        this.$toast.add({
          severity: "danger",
          summary: "Fehlerhafte Benutzerkonfiguration",
          detail: "Felder wie Passwort kontrollieren",
          life: 3000,
        });
      }
    },
    editProduct(ioData) {
      // this.ioDialogData = { ...ioData };
      console.log('editproduct')
      this.getUserList();
      console.log(ioData);
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.productDialog = true;
      // this.intval_Value = setInterval(this.updateOpenValue, 1000);
    },
    confirmDeleteProduct(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      // this.products = this.products.filter((val) => val.id !== this.product.id);
      let _index = this.findIndexById(this.ioDialogData._id);
      if (_index >= 0) {
        this.system_user[_index] = this.ioDialogData;
        SocketioService.delUser(this.ioDialogData, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getUserList();
          }
        });
        // SocketioService.removeUser(this.system_user[_index]);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Updated",
        //   life: 3000,
        // });
      } else {
        // this.product.id = this.createId();
        // this.product.code = this.createId();
        // this.product.image = "product-placeholder.svg";
        // this.products.push(this.product);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Created",
        //   life: 3000,
        // });
      }
      this.deleteProductDialog = false;
      this.resetDialogData();
      // this.$toast.add({
      //   severity: "success",
      //   summary: "Successful",
      //   detail: "Product Deleted",
      //   life: 3000,
      // });
    },
    updateOpenValue() {
      // let _index = this.findIndexById(this.ioDialogData._id);
      // if (_index >= 0) {
      //   // SocketioService.getStatusIO((response) => {
      //   //   this.ioDialogData.value.value = response[_index].value.value;
      //   // });

      // }
      SocketioService.getSingleValue(this.ioDialogData._id, (response) => {
        this.ioDialogData.value.value = response.value.value;
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.system_user.length; i++) {
        if (this.system_user[i]._id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      SocketioService.removeValue(this.selectedIOs);
      this.deleteProductsDialog = false;
      this.selectedIOs = null;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>